<template>
<div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
         <table-villages :villages="villages" @editForm=editForm></table-villages>
    </div>
    <edit-villages :id="id" :types="types" :city="city" @refresh="refresh"/>
</div>
</template>

<script>
import tableVillages from "@/views/component/Table/tableVillages.vue"
import editVillages from '@/views/component/Modal/ModalVillages/editVillages.vue'
export default {
    components:{
        tableVillages,
        editVillages
    },
    data(){
        return{
            id: null,
            villages: [],
            types: [],
            city: [],
            showPreloader: false
        }
    },
    methods: {
        editForm(id) {
            this.id = id
            setTimeout(() => {this.$bvModal.show('editVillages')}, 0);
        },
        villageType() {
            this.$http
            .get(`addresses/village/types`)
            .then((res) => {
                this.types = res.data
            })
        },
        cityList() {
            this.$http
            .get(`addresses/village/city-list`)
            .then((res) => {
                this.city = res.data
            })
        },  
        refresh(){
            this.showPreloader = true
            this.$http
            .get('addresses/villages')
            .then(res =>{
                this.villages = res.data
                this.$store.commit('pageData/setdataCount', this.villages.length)
                this.showPreloader = false
                this.$store.commit('REFRESH_DATA', false)
            })
        },
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.refresh()
        this.villageType()
        this.cityList()
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.villages = res.data
                    this.$store.commit('pageData/setdataCount', this.villages.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>

<style>

</style>