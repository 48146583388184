<template>
    <b-modal
        id="editVillages"
        title="Изменить"
        size="sm"
        hide-footer
        no-enforce-focus
        :no-close-on-backdrop="true"
        @shown="getVillage"
        @close="clear"
    >
    <b-row>
        <b-col>
            <label for="">Название <i class="label">*</i></label>
            <b-input v-model="village.name"></b-input>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <label for="">Полигон <i class="label">*</i></label>
            <b-input v-model="village.polygon"></b-input>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <label for="">Город <i class="label">*</i></label>
            <v-select
            v-model="village.city_id"
            :reduce="options => options.id"
            :options="city"
            label="name_ru"
            ></v-select>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <label for="">Tип деревня <i class="label">*</i></label>
            <v-select
            v-model.number="village.village_type_id"
            :reduce="options => options.id"
            :options="types"
            label="name"
            ></v-select>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <label for="">Широта (lat) <i class="label">*</i></label>
            <b-input v-model="village.lat"></b-input>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <label for="">Долгота (lng) <i class="label">*</i></label>
            <b-input v-model="village.lng"></b-input>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-checkbox class="mt-2" v-model="village.is_active" checked="true" name="check-button" switch inline>
                {{this.village.is_active ? 'Активный' : ' Не активный'}}
            </b-form-checkbox>
        </b-col>
    </b-row>
    <b-row class="mb-1 mt-2">
        <b-col class="d-flex justify-content-end">
        <b-button :disabled="submitButtonDisabled" @click="editVillage" variant="primary">Изменить</b-button>
        <b-button  @click="clear()" class="ml-1" variant="danger">Отмена</b-button>
        </b-col>
    </b-row>
    </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        vSelect
    },
    props: ['id','city','types'],
    data() {
        return {
            village: {},
            submitButtonDisabled: false
        }
    },
    methods: {
        getVillage() {
            this.$http
            .get(`addresses/villages/${this.id}/edit`)
            .then((res) => {
                this.village = res.data
            })
        },
        editVillage() {
            this.village.is_active = this.village.is_active == true ? '1' : '0' 
            this.submitButtonDisabled = true
            this.$http
            .patch(`addresses/villages/${this.id}`, this.village)
            .then((res) => {
                this.clear()
                this.$emit('refresh')
                this.$toast({
                component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `${res.data.message}`,
                    },
                })
            })
            .catch((err) => {
                this.$toast({
                component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
        clear() {
            this.$bvModal.hide('editVillages')
            this.village = {}
        }
    }
}
</script>

<style lang="scss" scoped>
.label {
    color: red;
}
</style>